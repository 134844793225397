import React from 'react'
import { graphql } from "gatsby"
import Img from "gatsby-image";
// import PropTypes from "prop-types"

// Components
import Layout from "../layout/layout";
import SEO from "../seo";
import SidebarForm from "../sidebar-form/sidebar-form"

// CSS
import "./content-template.scss"

// Layout.defaultProps = {
//   props: {}
// }

export default (props) => {
  const { markdownRemark } = props.data;

  return (
    <Layout>
      <SEO
        title={markdownRemark.frontmatter.title}
        description={markdownRemark.frontmatter.description}
      />
      <div className="ContentTemplate border-bottom">
        <div className="container padding-top-bottom">
          <div className="row"> 
            <div className="col-md-8">
            
                  <h1 className="padding">{markdownRemark.frontmatter.title}</h1>
                  
                  {markdownRemark.frontmatter.profile_img ? 
                    <div className="col-md-5 img-about remove-padding-left">
                    {markdownRemark.frontmatter.profile_img && (
                      <Img fluid={markdownRemark.frontmatter.profile_img.childImageSharp.fluid}/>
                    )}
                    </div> :
                    null
                  }
                  
                  <div className="col-md-12 remove-padding-left">
                    <div dangerouslySetInnerHTML={{
                          __html: markdownRemark.html
                        }} />
                  </div>
            </div>
            
            <div className="col-md-4">
                    <SidebarForm/>
            </div>  
          </div>
        </div> 
      </div>      
    </Layout>
  )
}

export const query = graphql`
  query ContentQuery($slug: String!) {
    markdownRemark(frontmatter: {
      slug: {
      eq: $slug
      }
    }) {
      html 
      frontmatter {
        title
        description
        slug
        priority
        profile_img {
        	publicURL
        	childImageSharp {
          	fluid(maxWidth: 1000) {
            	...GatsbyImageSharpFluid
          	}
        	}
      	}
      }
    } 
  }
`;